<template>
  <div class="pageContainer app-container studentContainer" style="position: relative">
    <el-tabs v-model="activeName" type="card" style="" @tab-click="changeTab">
      <el-tab-pane label="" name="td">
        <span slot="label">
          <i>校友信息</i>
        </span>
      </el-tab-pane>
    </el-tabs>
    <el-row style="margin-bottom: 10px">
      <el-input v-model="formData.stu_name" size="small" placeholder="姓名" style="max-width: 200px; margin-right: 10px;"/>
      <el-cascader v-model="formData.class_id"
                   :props="{
              label:'orgName',value:'orgId',emitPath:false}"
                   :options="classList" size="small" clearable
                   @change="handleChange"
                   style="max-width: 200px; margin-right: 10px; margin-left: 10px;"
                   placeholder="年级/系部/班级"></el-cascader>
      <el-input v-model="formData.phone" size="small" placeholder="手机号" style="max-width: 200px; margin-right: 10px;"/>
      <el-input v-model="formData.work_city" size="small" placeholder="工作城市" style="max-width: 200px; margin-right: 10px;"/>
      <el-button size="small" icon="el-icon-search" @click="onSearch" plain type="primary">查询</el-button>
      <el-button size="small" icon="el-icon-refresh" @click="onReset" type="warning">重置</el-button>
    </el-row>
    <el-table stripe :data="dataList" style="width: 100%; margin-bottom: 20px; color: #000" border size="mini">
        <el-table-column type="index" label="序号" width="55"></el-table-column>
      <el-table-column prop="stu_name" label="姓名" width="80"></el-table-column>
      <el-table-column prop="sex" label="性别" width="60">
        <template slot-scope="scope">
          <span v-if="scope.row.sex == '1'">男</span>
          <span v-if="scope.row.sex == '2'">女</span>
          <span v-if="scope.row.sex == '0'">未知</span>
        </template>
      </el-table-column>
        <el-table-column prop="phone" label="手机" width="100"></el-table-column>
      <el-table-column prop="graduate" label="教育阶段" width="120"></el-table-column>
      <el-table-column prop="departments_name" label="院（系）" width="120"></el-table-column>
      <el-table-column prop="grade_name" label="年级" width="120"></el-table-column>
      <el-table-column prop="class_name" label="班级" width="100"></el-table-column>
      <el-table-column prop="start_year" label="入学年份" width="100"></el-table-column>
        <el-table-column prop="city" label="工作城市" width="120"></el-table-column>
        <el-table-column prop="industry_name" label="所属行业" width="120"></el-table-column>
        <el-table-column prop="unit_nature_name" label="单位性质" width="100"></el-table-column>
        <el-table-column fixed="right" label="操作" width="180">
            <template slot-scope="scope">
              <el-tag size="mini" @click="onView(scope.row)" style="cursor: pointer;">详情</el-tag>

              <el-popconfirm width="250" confirmButtonText='确定' cancelButtonText='取消'
                               icon="el-icon-info" iconColor="red"
                               @confirm="unBind(scope.row)" placement="left" title="是否确定解绑？">
                    <el-tag slot="reference" type="warning" size="mini" style="margin-left: 10px;cursor: pointer">解绑</el-tag>
                </el-popconfirm>

              <el-popconfirm width="250" confirmButtonText='确定' cancelButtonText='取消'
                             icon="el-icon-info" iconColor="red"
                             @confirm="onDel(scope.row)" placement="left" title="是否确定删除？">
                <el-tag slot="reference" type="danger" size="mini" style="cursor: pointer;margin-left: 20px">删除</el-tag>

              </el-popconfirm>

            </template>
        </el-table-column>
    </el-table>
    <div class="block">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="current_page"
        :page-size="per_page"
        layout="total, prev, pager, next"
        :total="count"></el-pagination>
    </div>

    <el-dialog title="详情" :visible.sync="visible" width="800px" custom-class="width_800 cus_dialog">
      <el-form :model="detailForm" label-width="80px">
        <el-row>
          <el-col :span=12>
            <el-form-item label="姓名:" prop="plan_name">{{detailForm.stu_name}}</el-form-item>
          </el-col>
          <el-col :span=12>
            <el-form-item label="性别:" prop="plan_name">
              <span v-if="detailForm.sex == '1'">男</span>
              <span v-if="detailForm.sex == '2'">女</span>
              <span v-if="detailForm.sex == '0'">未知</span>
            </el-form-item>
          </el-col>
          <el-col :span=12>
            <el-form-item label="手机:" prop="plan_name">{{detailForm.phone}}</el-form-item>
          </el-col>
          <el-col :span=12>
            <el-form-item label="邮箱:" prop="plan_name">{{detailForm.mailbox}}</el-form-item>
          </el-col>

          <el-col :span=12>
            <el-form-item label="教育阶段:" prop="plan_name">{{detailForm.graduate}}</el-form-item>
          </el-col>
          <el-col :span=12>
            <el-form-item label="政治面貌:" prop="plan_name">{{detailForm.political_status_name}}</el-form-item>
          </el-col>

          <el-col :span=12>
            <el-form-item label="院（系）:" prop="plan_name">{{detailForm.departments_name}}</el-form-item>
          </el-col>
          <el-col :span=12>
            <el-form-item label="年级:" prop="plan_name">{{detailForm.grade_name}}</el-form-item>
          </el-col>
          <el-col :span=12>
            <el-form-item label="班级:" prop="plan_name">{{detailForm.class_name}}</el-form-item>
          </el-col>
          <el-col :span=12>
            <el-form-item label="入学年份:" prop="plan_name">{{ detailForm.start_year }}</el-form-item>
          </el-col>
          <el-col :span=12>
            <el-form-item label="工作城市:" prop="city">{{ detailForm.city }}</el-form-item>
          </el-col>
          <el-col :span=12>
            <el-form-item label="工作地址:" prop="city">{{ detailForm.site }}</el-form-item>
          </el-col>
          <el-col :span=12>
            <el-form-item label="所属行业:" prop="city">{{ detailForm.industry_name }}</el-form-item>
          </el-col>

          <el-col :span=12>
          <el-form-item label="单位性质:" prop="plan_name">{{detailForm.unit_nature_name}}</el-form-item>
          </el-col>



        </el-row>

      </el-form>

<!---->


<!--      -->


      <div slot="footer" class="dialog-footer">
        <el-button @click="visible = false" type="primary" plain>关 闭</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      count: 0,
      current_page: 1,
      per_page: 20,
      activeName: "td",
      dataList: [],
      showSP: false,
      formData: {},
      spword: "",
      showPrint: false,
      classList: [],
      visible:false,
      detailForm:{},
    };
  },
  mounted() {
    console.log(sessionStorage.getItem("auth"));
    this.getlist();
    this.getClass();
  },
  methods: {
    onView(row){
      this.visible = true
      this.detailForm = row
    },
    handleChange(e) {
      console.log(e)
      let name = this.$refs['cascaderClass'].getCheckedNodes()[0].pathLabels[2]
      this.formData.class_name = name
    },
    getClass() {
      this.$http.post("api/sys_office", {"parent_id": "0", pmid: "0"}).then((res) => {
        this.deleteEmptyGroup(res.data[0].children).then(r => {
          this.classList = r
        })

      });
    },
    // 处理没有children的分组
    deleteEmptyGroup(treeData) {
      return new Promise(resolve => {
        function traversal(data) {
          data.map(item => {
            for (let info in item) {
              if (item['children']) {
                if (item['children'].length > 0) {
                  traversal(item['children'])
                }
                if (item['children'].length == 0) {
                  delete item['children']
                }
              }
            }
          })
        }

        traversal(treeData)
        resolve(treeData)
      })
    },
    getlist() {
      this.$http
        .post("api/by_students_list_pc", {
          page: this.current_page,
          pagesize: this.per_page,
          stu_name: this.formData.stu_name,
          phone: this.formData.phone,
          class_id:this.formData.class_id,
          site:this.formData.work_city,
        })
        .then((res) => {
          if(res.data.data.data&&res.data.data.data.length>0){
              res.data.data.data.map(a=>{
                  if(a.work_city){
                      let citys = JSON.parse(a.work_city)
                      a.city = citys[1].name
                  }
              })
          }
          this.dataList = res.data.data.data;
          this.count = res.data.data.page.count;
          this.current_page = res.data.data.page.current_page;
          this.per_page = res.data.data.page.per_page;
        });
    },
    changeTab() {
      this.getlist();
    },
    onSearch() {
      this.getlist();
    },
    onReset() {
      this.formData = {};
      this.getlist();
    },
    onDel(row){
      this.$http.post("api/by_students_delete", {id: row.id}).then((res) => {
       this.$message.success('删除成功！')
        this.getlist();
      });
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      this.current_page = val;
      this.getlist();
    },
    handleCancel() {},
    printIframe() {
      var iframe = document.getElementById("printPage");
      iframe.contentWindow.focus();
      iframe.contentWindow.print();
    },

      unBind(row){
          let params = {
              openid: row.openid,
              user_id: row.id,
          }
          this.$http.post('api/stu_untie', params).then(res => {
              if (res.data.code == 200) {
                  this.$message.success('解绑成功')
              } else {
                  this.$message.error(res.data.msg)
              }
          })
      },
  },
};
</script>


